import axios from 'axios'

axios.defaults.withCredentials = true

const httpClient = axios.create({
  withCredentials: true,
  baseURL: process.env.NEXT_PUBLIC_KACH_API_BASE_URL,
})

export default httpClient
