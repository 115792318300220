import { useToast } from '@chakra-ui/react'
import axios from 'axios'
import { useCallback } from 'react'

export const useError = () => {
  const toast = useToast()

  const getErrorMessage = useCallback((err: Error): any => {
    if (axios.isAxiosError(err)) {
      switch (err.response?.data?.code) {
        case 'formula_already_exists':
          return 'Esta formula ya existe en la empresa'

        case 'bad_credentials':
          return 'Credenciales inválidas'

        case 'ein_already_exists':
          return 'El CUIT ya existe actualmente en otra empresa'

        case 'name_already_exists':
          return 'El nombre ya existe actualmente en otra empresa'

        case 'email_already_exists':
          return 'El correo electrónico ya se encuentra en uso por otro usuario'

        case 'domain_already_exists':
          return 'El dominio ya existe actualmente en otra empresa'

        default:
          return 'Ocurrió un error inesperado'
      }
    }
  }, [])

  const handleError = useCallback((err: any) => {
    toast({
      title: getErrorMessage(err),
      status: 'error',
    })
  }, [])

  return {
    getErrorMessage,
    handleError,
  }
}
