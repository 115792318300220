import { useForm } from 'react-hook-form'
import { KachLogo } from '../components/KachLogo'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { useCallback, useState } from 'react'
import { AuthService } from '../services/auth.service'
import { useError } from '../hooks/use-error'
import { Button } from '../components/Button'
import { useRouter } from 'next/router'
import { GetServerSideProps } from 'next'
import { composeServerSideProps } from 'next-composition'
import { auth } from '../ssr/auth'
import { publicRoute } from '../ssr/public-route'
import { compose } from '../ssr/compose'
import { setBrowserCookiesToHTTPClient } from '../ssr/set-browser-cookies-to-http-client'
import { buildTestId } from 'kach-commons'

const LoginSchema = z.object({
  user: z.string().min(1, 'Campo requerido!'),
  password: z.string().min(1, 'Campo requerido!'),
})

export interface ILoginSchema extends z.infer<typeof LoginSchema> {}

export default function Login() {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<ILoginSchema>({
    resolver: zodResolver(LoginSchema),
  })

  const [isLoading, setIsLoading] = useState(false)

  const { handleError } = useError()

  const router = useRouter()

  const onLogin = useCallback((input: ILoginSchema) => {
    setIsLoading(true)
    AuthService.login(input)
      .then((res) => {
        router.push('/')
      })
      .catch(handleError)
      .finally(() => setIsLoading(false))
  }, [])

  return (
    <>
      <div className='flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-md'>
          <div className='items-center justify-center w-full flex'>
            <KachLogo
              styles={{
                width: '10rem',
              }}
            />
          </div>
        </div>

        <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
          <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
            <form
              onSubmit={handleSubmit(onLogin)}
              className='space-y-6'
              action='#'
              method='POST'
            >
              <div>
                <label
                  htmlFor='user'
                  className='block text-sm font-medium text-gray-700'
                >
                  Username
                </label>
                <div className='mt-1'>
                  <input
                    {...register('user')}
                    id='user'
                    name='user'
                    type='user'
                    autoComplete='user'
                    required
                    className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor='password'
                  className='block text-sm font-medium text-gray-700'
                >
                  Contraseña
                </label>
                <div className='mt-1'>
                  <input
                    {...register('password')}
                    id='password'
                    name='password'
                    type='password'
                    autoComplete='current-password'
                    required
                    className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
                </div>
              </div>

              <div>
                <Button
                  {...buildTestId('login-btn')}
                  isLoading={isLoading}
                  type='submit'
                  className='flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                >
                  Ingresar
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export const getServerSideProps = compose(
  setBrowserCookiesToHTTPClient,
  auth,
  publicRoute,
)
