import httpClient from '../http/http-client'
import { ILoginSchema } from '../pages/login'

export class AuthService {
  static async login(input: ILoginSchema) {
    const response = await httpClient.post('/v1/backoffice/login', input)

    return response.data
  }

  static async me() {
    const response = await httpClient.get('/v1/backoffice/auth/me')

    return response.data
  }

  static async signInAsUser(userId: number): Promise<{
    mocked: boolean
    targetURL: string
  }> {
    const response = await httpClient.get(
      `/v1/backoffice/mock/user/sign-in/${userId}`,
    )

    return response.data
  }
}
